import React, { FC } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import HeroCarousel from "../../components/landing-page/home/HeroCarousel"
import HomeSellingPoints from "../../../components/home/home-selling-points/HomeSellingPoints"
import Radiation from "../../../components/home/radiation/Radiation"
import WhyWeDoThis from "../../../components/home/why-we-do-this/WhyWeDoThis"
import BlogFeed from "../../components/landing-page/BlogFeed"
import InstaFeed from "../../../components/home/insta-feed/InstaFeed"
import HomeMajorFeature from "../../components/landing-page/home/HomeMajorFeature"
import LazyLoad from "react-lazyload"
import { linkBlue } from "../../constants/colors"
import Subheading from "../../components/landing-page/Subheading"
import { graphql } from "gatsby"
import styled from "styled-components"
import GLink from "../../../gatsby/GLink"
import LPShopByImpactCarouselBlock from "../../components/landing-page/new/components/impact/LPShopByImpactCarouselBlock"
import { screenSizes } from "@social-supermarket/social-supermarket-components"
import HomeProductCarousel from "../../../components/home/HomeProductCarousel"
import LPBrochureCTABlock from "../../components/landing-page/new/components/cta/LPBrochureCTABlock"
import LPBannerTabsBlock from "../../components/landing-page/new/components/banner/LPBannerTabsBlock"
import { ProductType } from "@social-supermarket/social-supermarket-model"
import LPBanner6 from "../../components/landing-page/new/components/banner/LPBanner6"
import LPProductCarouselFeatureBlock from "../../components/landing-page/new/components/products/LPProductCarouselFeatureBlock"
import LPImageFeatureBlock from "../../components/landing-page/new/components/general/LPImageFeatureBlock"

const CustomerFavourites = styled.div`
  display: flex;
`
const MajorFeatureContainer = styled.div`
  padding: 60px 20px;

  @media (max-width: ${screenSizes.tablet}px) {
    padding: 0;
  }
`

const CustomerFavouritesSubheading = styled(Subheading)`
  margin-bottom: 45px;
  @media (max-width: ${screenSizes.tablet}px) {
    margin-bottom: 30px;
  }
  @media (max-width: ${screenSizes.smallMobile}px) {
    margin-bottom: 35px;
  }
`
const ViewAll = styled(GLink)`
  font-size: 0.8em;
  margin-left: 10px;
  position: relative;
  bottom: 1px;
  color: ${linkBlue};
`
const StyledHeroCarousel = styled(HeroCarousel)``

interface Props {
  recentProducts: ProductType[]
}

const HomePage: FC<Props> = ({ recentProducts }) => {
  return (
    <Layout fullWidth>
      <SEO
        title={"Social Supermarket"}
        description={
          "We believe in a world where every business can be a force for good. We curate the best products from brands with net-positive impact. That means every purchase you make sends out ripples out change. We champions brands that make a real difference in our world. There's nothing neutral about us."
        }
        skipTemplate
      />
      <LPBannerTabsBlock />
      <HomeSellingPoints />
      <LPProductCarouselFeatureBlock
        title={"Eco-friendly Gifts"}
        collectionName={"Hamper Landing Page"}
        backgroundColor={"#f6f9f6"}
        useImpactImage
        large
        to={"/product-category/sustainable-gifts"}
      />
      <LPImageFeatureBlock
        title={<>Create ripples of change with every purchase</>}
        description={
          "We are a one-stop shop with +200 brands tackling human and environmental issues, empowering you to make a real difference every time you shop."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/The-Social-Supermarket-Branded-Merch-10-square-1-scaled.jpg"
        }
        colorWay={0}
        backgroundColor={"white"}
        to={"/about"}
      />
      <LPProductCarouselFeatureBlock
        title={"Sustainable Corporate Merchandise"}
        collectionName={"Branded Merchandise Landing Page"}
        backgroundColor={"#fcf7eb"}
        showImpactArrow
        to={"/product-category/sustainable-branded-merchandise"}
      />
      <LPImageFeatureBlock
        title={<>Ethical Gift Voucher</>}
        description={
          "Send them a Social Supermarket gift card straight to their inbox and let them choose from over 2,000 sustainable and social impact products."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Untitled-design-10.png"
        }
        colorWay={0}
        backgroundColor={"white"}
        invert
        to={"/product/eco-friendly-gift-card"}
        linkLabel={"Order Gift Voucher Now"}
      />

      {/*<ColorWrapper background={white}>*/}
      {/*  <Fade>*/}
      {/*    <Block>*/}
      {/*      <InnerBlock>*/}
      {/*        <CustomerFavourites>*/}
      {/*          <CustomerFavouritesSubheading isBordered borderColor={skyBlue}>*/}
      {/*            Ready-to-go Gifts{" "}*/}
      {/*          </CustomerFavouritesSubheading>*/}
      {/*          <ViewAll to={"/product-category/christmas-hampers"}>View All</ViewAll>*/}
      {/*        </CustomerFavourites>*/}
      {/*        <AlgoliaProductCarousel*/}
      {/*          collectionName={"Christmas Landing Page - Our Customer Favourites"}*/}
      {/*          containerStyle={{ width: "100%" }}*/}
      {/*        />*/}
      {/*      </InnerBlock>*/}
      {/*    </Block>*/}
      {/*  </Fade>*/}
      {/*</ColorWrapper>*/}
      {/*<MajorFeatureContainer>*/}
      {/*  <HomeMajorFeature index={0} />*/}
      {/*</MajorFeatureContainer>*/}
      <LPShopByImpactCarouselBlock />
      <LPBrochureCTABlock
        subheading={"RIPPLES OF CHANGE"}
        title={"Our 2022 Impact Report"}
        description={
          "Social Supermarket's mission is to enable fundamental societal change by connecting conscious buyers and innovative Social Enterprise brands. Here is a look at what we achieved together."
        }
        buttonLabel={"Download Impact Report"}
        buttonTo={
          "https://drive.google.com/file/d/1_KzvQcCRZbPLa_fOw2RUDJo5fWdkcOJE/view?usp=sharing"
        }
        imageSrc={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/SSM-Impact-Report-2022.jpg"
        }
      />

      <HomeProductCarousel products={recentProducts} />
      <Radiation />
      <WhyWeDoThis />
      {/*<div className={style.majorFeatureContainer}>*/}
      {/*  <HomeMajorFeature index={1} />*/}
      {/*</div>*/}
      <BlogFeed />
      <LazyLoad>
        <InstaFeed />
      </LazyLoad>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpImpact {
      nodes {
        slug
        icon {
          iconSvg
        }
      }
    }
  }
`

export default HomePage
